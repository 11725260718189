import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const MousePointerClick: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm9 9 5 12 1.8-5.2L21 14Z' }],
    ['path', { d: 'M7.2 2.2 8 5.1' }],
    ['path', { d: 'm5.1 8-2.9-.8' }],
    ['path', { d: 'M14 4.1 12 6' }],
    ['path', { d: 'm6 12-1.9 2' }],
  ],
];

export default MousePointerClick;
