import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SearchCheck: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm8 11 2 2 4-4' }],
    ['circle', { cx: '11', cy: '11', r: '8' }],
    ['path', { d: 'm21 21-4.3-4.3' }],
  ],
];

export default SearchCheck;
