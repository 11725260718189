import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const PencilRuler: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm15 5 4 4' }],
    ['path', { d: 'M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13' }],
    ['path', { d: 'm8 6 2-2' }],
    ['path', { d: 'm2 22 5.5-1.5L21.17 6.83a2.82 2.82 0 0 0-4-4L3.5 16.5Z' }],
    ['path', { d: 'm18 16 2-2' }],
    ['path', { d: 'm17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17' }],
  ],
];

export default PencilRuler;
