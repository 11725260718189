import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const EggOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M6.399 6.399C5.362 8.157 4.65 10.189 4.5 12c-.37 4.43 1.27 9.95 7.5 10 3.256-.026 5.259-1.547 6.375-3.625',
      },
    ],
    [
      'path',
      {
        d: 'M19.532 13.875A14.07 14.07 0 0 0 19.5 12c-.36-4.34-3.95-9.96-7.5-10-1.04.012-2.082.502-3.046 1.297',
      },
    ],
    ['line', { x1: '2', x2: '22', y1: '2', y2: '22' }],
  ],
];

export default EggOff;
