import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BookHeadphones: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20' }],
    ['circle', { cx: '9', cy: '12', r: '1' }],
    ['path', { d: 'M8 12v-2a4 4 0 0 1 8 0v2' }],
    ['circle', { cx: '15', cy: '12', r: '1' }],
  ],
];

export default BookHeadphones;
