import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const PackageX: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14',
      },
    ],
    ['path', { d: 'm7.5 4.27 9 5.15' }],
    ['polyline', { points: '3.29 7 12 12 20.71 7' }],
    ['line', { x1: '12', x2: '12', y1: '22', y2: '12' }],
    ['path', { d: 'm17 13 5 5m-5 0 5-5' }],
  ],
];

export default PackageX;
