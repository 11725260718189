import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ListChecks: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 17 2 2 4-4' }],
    ['path', { d: 'm3 7 2 2 4-4' }],
    ['path', { d: 'M13 6h8' }],
    ['path', { d: 'M13 12h8' }],
    ['path', { d: 'M13 18h8' }],
  ],
];

export default ListChecks;
