import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Cable: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 9a2 2 0 0 1-2-2V5h6v2a2 2 0 0 1-2 2Z' }],
    ['path', { d: 'M3 5V3' }],
    ['path', { d: 'M7 5V3' }],
    ['path', { d: 'M19 15V6.5a3.5 3.5 0 0 0-7 0v11a3.5 3.5 0 0 1-7 0V9' }],
    ['path', { d: 'M17 21v-2' }],
    ['path', { d: 'M21 21v-2' }],
    ['path', { d: 'M22 19h-6v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2Z' }],
  ],
];

export default Cable;
