import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ShieldOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38' }],
  ],
];

export default ShieldOff;
