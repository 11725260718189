import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Tangent: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '17', cy: '4', r: '2' }],
    ['path', { d: 'M15.59 5.41 5.41 15.59' }],
    ['circle', { cx: '4', cy: '17', r: '2' }],
    ['path', { d: 'M12 22s-4-9-1.5-11.5S22 12 22 12' }],
  ],
];

export default Tangent;
