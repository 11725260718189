import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Torus: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['ellipse', { cx: '12', cy: '11', rx: '3', ry: '2' }],
    ['ellipse', { cx: '12', cy: '12.5', rx: '10', ry: '8.5' }],
  ],
];

export default Torus;
