import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const PlaneTakeoff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 22h20' }],
    [
      'path',
      {
        d: 'M6.36 17.4 4 17l-2-4 1.1-.55a2 2 0 0 1 1.8 0l.17.1a2 2 0 0 0 1.8 0L8 12 5 6l.9-.45a2 2 0 0 1 2.09.2l4.02 3a2 2 0 0 0 2.1.2l4.19-2.06a2.41 2.41 0 0 1 1.73-.17L21 7a1.4 1.4 0 0 1 .87 1.99l-.38.76c-.23.46-.6.84-1.07 1.08L7.58 17.2a2 2 0 0 1-1.22.18Z',
      },
    ],
  ],
];

export default PlaneTakeoff;
