import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BookmarkCheck: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2Z' }],
    ['path', { d: 'm9 10 2 2 4-4' }],
  ],
];

export default BookmarkCheck;
