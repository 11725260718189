import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Satellite: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M13 7 9 3 5 7l4 4' }],
    ['path', { d: 'm17 11 4 4-4 4-4-4' }],
    ['path', { d: 'm8 12 4 4 6-6-4-4Z' }],
    ['path', { d: 'm16 8 3-3' }],
    ['path', { d: 'M9 21a6 6 0 0 0-6-6' }],
  ],
];

export default Satellite;
