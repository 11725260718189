import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Sparkles: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z',
      },
    ],
    ['path', { d: 'M5 3v4' }],
    ['path', { d: 'M19 17v4' }],
    ['path', { d: 'M3 5h4' }],
    ['path', { d: 'M17 19h4' }],
  ],
];

export default Sparkles;
