import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BellOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8.7 3A6 6 0 0 1 18 8a21.3 21.3 0 0 0 .6 5' }],
    ['path', { d: 'M17 17H3s3-2 3-9a4.67 4.67 0 0 1 .3-1.7' }],
    ['path', { d: 'M10.3 21a1.94 1.94 0 0 0 3.4 0' }],
    ['path', { d: 'm2 2 20 20' }],
  ],
];

export default BellOff;
