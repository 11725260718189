import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Pizza: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 11h.01' }],
    ['path', { d: 'M11 15h.01' }],
    ['path', { d: 'M16 16h.01' }],
    ['path', { d: 'm2 16 20 6-6-20A20 20 0 0 0 2 16' }],
    ['path', { d: 'M5.71 17.11a17.04 17.04 0 0 1 11.4-11.4' }],
  ],
];

export default Pizza;
