import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Spade: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M5 9c-1.5 1.5-3 3.2-3 5.5A5.5 5.5 0 0 0 7.5 20c1.8 0 3-.5 4.5-2 1.5 1.5 2.7 2 4.5 2a5.5 5.5 0 0 0 5.5-5.5c0-2.3-1.5-4-3-5.5l-7-7-7 7Z',
      },
    ],
    ['path', { d: 'M12 18v4' }],
  ],
];

export default Spade;
