import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Podcast: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '11', r: '1' }],
    ['path', { d: 'M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5Z' }],
    ['path', { d: 'M8 14a5 5 0 1 1 8 0' }],
    ['path', { d: 'M17 18.5a9 9 0 1 0-10 0' }],
  ],
];

export default Podcast;
