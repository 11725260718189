import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const PictureInPicture2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 9V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h4' }],
    ['rect', { width: '10', height: '7', x: '12', y: '13', rx: '2' }],
  ],
];

export default PictureInPicture2;
