import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ScrollText: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M8 21h12a2 2 0 0 0 2-2v-2H10v2a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v3h4' }],
    ['path', { d: 'M19 17V5a2 2 0 0 0-2-2H4' }],
    ['path', { d: 'M15 8h-5' }],
    ['path', { d: 'M15 12h-5' }],
  ],
];

export default ScrollText;
