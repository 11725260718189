import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Ratio: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '12', height: '20', x: '6', y: '2', rx: '2' }],
    ['rect', { width: '20', height: '12', x: '2', y: '6', rx: '2' }],
  ],
];

export default Ratio;
