import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const TableProperties: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 3v18' }],
    ['rect', { width: '18', height: '18', x: '3', y: '3', rx: '2' }],
    ['path', { d: 'M21 9H3' }],
    ['path', { d: 'M21 15H3' }],
  ],
];

export default TableProperties;
