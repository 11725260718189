import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BookHeart: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20' }],
    [
      'path',
      {
        d: 'M16 8.2C16 7 15 6 13.8 6c-.8 0-1.4.3-1.8.9-.4-.6-1-.9-1.8-.9C9 6 8 7 8 8.2c0 .6.3 1.2.7 1.6h0C10 11.1 12 13 12 13s2-1.9 3.3-3.1h0c.4-.4.7-1 .7-1.7z',
      },
    ],
  ],
];

export default BookHeart;
