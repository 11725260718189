import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Cpu: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '4', y: '4', width: '16', height: '16', rx: '2' }],
    ['rect', { x: '9', y: '9', width: '6', height: '6' }],
    ['path', { d: 'M15 2v2' }],
    ['path', { d: 'M15 20v2' }],
    ['path', { d: 'M2 15h2' }],
    ['path', { d: 'M2 9h2' }],
    ['path', { d: 'M20 15h2' }],
    ['path', { d: 'M20 9h2' }],
    ['path', { d: 'M9 2v2' }],
    ['path', { d: 'M9 20v2' }],
  ],
];

export default Cpu;
