import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Church: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm18 7 4 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9l4-2' }],
    ['path', { d: 'M14 22v-4a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v4' }],
    ['path', { d: 'M18 22V5l-6-3-6 3v17' }],
    ['path', { d: 'M12 7v5' }],
    ['path', { d: 'M10 9h4' }],
  ],
];

export default Church;
