import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Ribbon: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M17.75 9.01c-.52 2.08-1.83 3.64-3.18 5.49l-2.6 3.54-2.97 4-3.5-2.54 3.85-4.97c-1.86-2.61-2.8-3.77-3.16-5.44',
      },
    ],
    [
      'path',
      {
        d: 'M17.75 9.01A7 7 0 0 0 6.2 9.1C6.06 8.5 6 7.82 6 7c0-3.5 2.83-5 5.98-5C15.24 2 18 3.5 18 7c0 .73-.09 1.4-.25 2.01Z',
      },
    ],
    ['path', { d: 'm9.35 14.53 2.64-3.31' }],
    ['path', { d: 'm11.97 18.04 2.99 4 3.54-2.54-3.93-5' }],
    ['path', { d: 'M14 8c0 1-1 2-2.01 3.22C11 10 10 9 10 8a2 2 0 1 1 4 0' }],
  ],
];

export default Ribbon;
