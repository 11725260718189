import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Antenna: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 12 7 2' }],
    ['path', { d: 'm7 12 5-10' }],
    ['path', { d: 'm12 12 5-10' }],
    ['path', { d: 'm17 12 5-10' }],
    ['path', { d: 'M4.5 7h15' }],
    ['path', { d: 'M12 16v6' }],
  ],
];

export default Antenna;
