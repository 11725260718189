import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const TrafficCone: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M9.3 6.2a4.55 4.55 0 0 0 5.4 0' }],
    ['path', { d: 'M7.9 10.7c.9.8 2.4 1.3 4.1 1.3s3.2-.5 4.1-1.3' }],
    [
      'path',
      {
        d: 'M13.9 3.5a1.93 1.93 0 0 0-3.8-.1l-3 10c-.1.2-.1.4-.1.6 0 1.7 2.2 3 5 3s5-1.3 5-3c0-.2 0-.4-.1-.5Z',
      },
    ],
    [
      'path',
      {
        d: 'm7.5 12.2-4.7 2.7c-.5.3-.8.7-.8 1.1s.3.8.8 1.1l7.6 4.5c.9.5 2.1.5 3 0l7.6-4.5c.7-.3 1-.7 1-1.1s-.3-.8-.8-1.1l-4.7-2.8',
      },
    ],
  ],
];

export default TrafficCone;
