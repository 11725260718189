import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ShieldX: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10' }],
    ['path', { d: 'm14.5 9-5 5' }],
    ['path', { d: 'm9.5 9 5 5' }],
  ],
];

export default ShieldX;
