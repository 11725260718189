import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Wallet: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 12V7H5a2 2 0 0 1 0-4h14v4' }],
    ['path', { d: 'M3 5v14a2 2 0 0 0 2 2h16v-5' }],
    ['path', { d: 'M18 12a2 2 0 0 0 0 4h4v-4Z' }],
  ],
];

export default Wallet;
