import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const HelpingHand: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm3 15 5.12-5.12A3 3 0 0 1 10.24 9H13a2 2 0 1 1 0 4h-2.5m4-.68 4.17-4.89a1.88 1.88 0 0 1 2.92 2.36l-4.2 5.94A3 3 0 0 1 14.96 17H9.83a2 2 0 0 0-1.42.59L7 19',
      },
    ],
    ['path', { d: 'm2 14 6 6' }],
  ],
];

export default HelpingHand;
