import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ClipboardEdit: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '8', height: '4', x: '8', y: '2', rx: '1', ry: '1' }],
    ['path', { d: 'M10.42 12.61a2.1 2.1 0 1 1 2.97 2.97L7.95 21 4 22l.99-3.95 5.43-5.44Z' }],
    ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5' }],
    ['path', { d: 'M4 13.5V6a2 2 0 0 1 2-2h2' }],
  ],
];

export default ClipboardEdit;
