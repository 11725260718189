import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Layers3: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83Z',
      },
    ],
    [
      'path',
      {
        d: 'm6.08 9.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59',
      },
    ],
    [
      'path',
      {
        d: 'm6.08 14.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59',
      },
    ],
  ],
];

export default Layers3;
