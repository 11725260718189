import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Router: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '20', height: '8', x: '2', y: '14', rx: '2' }],
    ['path', { d: 'M6.01 18H6' }],
    ['path', { d: 'M10.01 18H10' }],
    ['path', { d: 'M15 10v4' }],
    ['path', { d: 'M17.84 7.17a4 4 0 0 0-5.66 0' }],
    ['path', { d: 'M20.66 4.34a8 8 0 0 0-11.31 0' }],
  ],
];

export default Router;
