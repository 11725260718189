import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const TextSelect: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 3a2 2 0 0 0-2 2' }],
    ['path', { d: 'M19 3a2 2 0 0 1 2 2' }],
    ['path', { d: 'M21 19a2 2 0 0 1-2 2' }],
    ['path', { d: 'M5 21a2 2 0 0 1-2-2' }],
    ['path', { d: 'M9 3h1' }],
    ['path', { d: 'M9 21h1' }],
    ['path', { d: 'M14 3h1' }],
    ['path', { d: 'M14 21h1' }],
    ['path', { d: 'M3 9v1' }],
    ['path', { d: 'M21 9v1' }],
    ['path', { d: 'M3 14v1' }],
    ['path', { d: 'M21 14v1' }],
    ['line', { x1: '7', x2: '15', y1: '8', y2: '8' }],
    ['line', { x1: '7', x2: '17', y1: '12', y2: '12' }],
    ['line', { x1: '7', x2: '13', y1: '16', y2: '16' }],
  ],
];

export default TextSelect;
