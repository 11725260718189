import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Fuel: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '3', x2: '15', y1: '22', y2: '22' }],
    ['line', { x1: '4', x2: '14', y1: '9', y2: '9' }],
    ['path', { d: 'M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18' }],
    [
      'path',
      { d: 'M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2h0a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5' },
    ],
  ],
];

export default Fuel;
