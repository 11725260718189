import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Route: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '6', cy: '19', r: '3' }],
    ['path', { d: 'M9 19h8.5a3.5 3.5 0 0 0 0-7h-11a3.5 3.5 0 0 1 0-7H15' }],
    ['circle', { cx: '18', cy: '5', r: '3' }],
  ],
];

export default Route;
