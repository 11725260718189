import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Octagon: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'polygon',
      { points: '7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2' },
    ],
  ],
];

export default Octagon;
