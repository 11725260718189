import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const CaseUpper: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm3 15 4-8 4 8' }],
    ['path', { d: 'M4 13h6' }],
    ['path', { d: 'M15 11h4.5a2 2 0 0 1 0 4H15V7h4a2 2 0 0 1 0 4' }],
  ],
];

export default CaseUpper;
