import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const DatabaseZap: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3' }],
    ['path', { d: 'M3 5V19A9 3 0 0 0 15 21.84' }],
    ['path', { d: 'M21 5V8' }],
    ['path', { d: 'M21 12L18 17H22L19 22' }],
    ['path', { d: 'M3 12A9 3 0 0 0 14.59 14.87' }],
  ],
];

export default DatabaseZap;
