import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Presentation: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 3h20' }],
    ['path', { d: 'M21 3v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V3' }],
    ['path', { d: 'm7 21 5-5 5 5' }],
  ],
];

export default Presentation;
