import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const XOctagon: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'polygon',
      { points: '7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2' },
    ],
    ['path', { d: 'm15 9-6 6' }],
    ['path', { d: 'm9 9 6 6' }],
  ],
];

export default XOctagon;
