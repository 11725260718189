import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Tent: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3.5 21 14 3' }],
    ['path', { d: 'M20.5 21 10 3' }],
    ['path', { d: 'M15.5 21 12 15l-3.5 6' }],
    ['path', { d: 'M2 21h20' }],
  ],
];

export default Tent;
