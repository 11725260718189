import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const FlaskRound: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 2v7.31' }],
    ['path', { d: 'M14 9.3V1.99' }],
    ['path', { d: 'M8.5 2h7' }],
    ['path', { d: 'M14 9.3a6.5 6.5 0 1 1-4 0' }],
    ['path', { d: 'M5.52 16h12.96' }],
  ],
];

export default FlaskRound;
