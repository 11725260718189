import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Monitor: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '20', height: '14', x: '2', y: '3', rx: '2' }],
    ['line', { x1: '8', x2: '16', y1: '21', y2: '21' }],
    ['line', { x1: '12', x2: '12', y1: '17', y2: '21' }],
  ],
];

export default Monitor;
