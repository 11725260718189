import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Package2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z' }],
    ['path', { d: 'm3 9 2.45-4.9A2 2 0 0 1 7.24 3h9.52a2 2 0 0 1 1.8 1.1L21 9' }],
    ['path', { d: 'M12 3v6' }],
  ],
];

export default Package2;
