import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Warehouse: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M22 8.35V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.35A2 2 0 0 1 3.26 6.5l8-3.2a2 2 0 0 1 1.48 0l8 3.2A2 2 0 0 1 22 8.35Z',
      },
    ],
    ['path', { d: 'M6 18h12' }],
    ['path', { d: 'M6 14h12' }],
    ['rect', { width: '12', height: '12', x: '6', y: '10' }],
  ],
];

export default Warehouse;
